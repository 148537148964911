.stripe-fieldset {
  border-style: none;
  padding: 0;
}

.StripeElement {
  border-width: 1px;
  border-style: solid;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  padding: 10px 14px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.00938em;
  font-family: 'Roboto, Helvetica, Arial, sans-serif';
}

.StripeElement--focus {
  border-width: 2px;
  border-color: #2979ff;
}

.StripeElement--invalid {
  border-width: 2px;
  border-color: #d32f2f;
  color: #d32f2f;
}

.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.hide-table-title {
  color: transparent;
}
